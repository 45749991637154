import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div className="body-class" style={{ color: "#b9cbe8" }}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="description" content="A personal tech blog covering a wide range of topics including AI, game development, electric motorcycles, and business development." />
        <meta name="keywords" content="Tech Blog, Personal Projects, AI, Game Development, Electric Motorcycles, Business Development, Innovation, Technology, Electric Vehicles, Artificial Intelligence, Gaming"/>
        <meta property="og:title" content="Innovation Intersection: AI, Gaming, and Electric Mobility"/>
        <meta property="og:description" content="Welcome to Innovation Intersection, a unique tech blog diving deep into the exciting worlds of AI, game development, and electric motorcycles. Join us as we explore, experiment, and innovate, turning ideas into reality and driving technology forward."/>
        <meta property="og:url" content="beeson.dev"/>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
